/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background: #FFFFFF;
}

a {
  text-decoration: underline;
}
a:hover {
	text-decoration: none;
}
html {
  height: 100%;
}
.Page, #root, body {
  height: inherit;
}

@font-face {
	font-family: 'Nolan Next';
  font-weight: 400;
	src: local('Nolan Next Regular'), url(./fonts/Nolan\ Next\ Regular.otf) format('opentype');
}
@font-face {
	font-family: 'Nolan Next';
	font-weight: bold;
	src: local('Nolan Next Bold'), url(./fonts/Nolan\ Next\ Bold.otf) format('opentype')
}
@font-face {
	font-family: 'Nolan Next';
	font-weight: 500;
	src: local('Nolan Next Medium'), url(./fonts/Nolan\ Next\ Medium.otf) format('opentype')
}
