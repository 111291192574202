.header {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 500;
  margin: 20px 0 10px;
  display: flex;
}
.password_rule {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.password_rule_description {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 300;
  margin-left: 10px;
}
.rules {
  margin-right: auto;
}
