.nav {
  background: #2d54e8;
}
.nav_inner {
  height: 67px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.nav a {
  color: white;
}
.links {
  display: flex;
  align-items: center;
}
.link {
  text-decoration: none;
}
.links .link {
  margin: 0 10px;
  font-size: 13px;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.logo {
  text-decoration: none;
  height: 36px;
}
.logo img {
  width: auto;
  height: 100%;
}

@media only screen and (max-width: 414px) {
  .nav_inner {
    padding: 0 20px;
  }
  .nav_inner svg {
    width: 100px;
  }
  .logo {
    width: 140px;
  }
}
