.button {
  background-color: #2d54e8 !important;
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
  border: 0;
  color: #ffffff !important;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
}
.button:hover {
  background-color: #394DDC !important;
}
.button:disabled {
  background-color: #ccc !important;
}
.button.success {
  background-color: #3bab6a !important;
}
.button.fullWidth {
  max-width: 100%;
}
