.page {
  background: #ffffff;
  display: flex;
  flex-flow: column;
  height: 80%;
  width: 100%;
}
.container {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
}
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 798px;
  height: 100%;
  padding: 12px;
  padding-top: 25px;
  margin: 0 auto;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.mobile_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}
