.page {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: -66px;
}
.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  max-width: 500px;
  padding: 40px;
  box-sizing: border-box;
}
.header {
  margin-bottom: 10px;
  text-align: center;
}
.successDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 150px;
}
.successCheckContainer {
  height: 60px;
}
.successCheck {
  width: 40px;
  height: 100%;
}
@media only screen and (max-width: 414px) {
  .container {
    padding: 20px;
    height: 100vh;
    justify-content: flex-start;
    padding-top: 80px;
  }
}
