.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  color: #4a4a4a;
}
.safetyNetContainer {
  margin-top: 30px;
  width: 60%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.title {
  margin: 25px 0;
  text-align: center;
  font-weight: bold;
  font-size: 35px;
}
.subtitle {
  line-height: 1.2;
  color: #757575;
}
.resourcePanel {
  width: 50%;
  margin: 15px 0;
}
.disclaimer {
  width: 50%;
  font-size: 14px;
  line-height: 1.2
}
.resourceCard {
  max-width: 100%;
  display: flex;
  border-radius: 4px;
  margin: 20px 0;
  padding: 15px 20px;
  align-items: 'center'
}
.resourceIcon {
  margin-right: 15px
}
.resourceDisplayText{
  font-weight: bold;
  margin-bottom: 5px;
}
a {
  text-decoration: none;
}
.underline {
  text-decoration: underline
}
.skip {
  margin-top: 50px;
  color: #2c55e8;
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 414px) {
  .resourcePanel, .disclaimer, .subtitle {
    width: 93%
  }
  .safetyNetContainer {
    width: 95%
  }
}
