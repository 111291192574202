.final_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  margin: 0;
}
.questiontitle {
  font-weight: bold;
  text-align: center;
}
.questionbody {
  margin-top: -20px;
  text-align: center;
}
.final_container p {
  margin-bottom: 20px;
  line-height: 1.25;
}
.successquestions {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100px;
  align-items: center;
  color: #4a4a4a;
  font-size: 13px
}
.successCheckContainer {
  height: 60px;
  width: 60px;
}
.successCheck {
  width: 100%;
  height: 100%;
}
.successtitle {
  margin: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.successtext {
  width: 80%;
  text-align: center;
  margin-bottom: 15px
}
.successContainer {
  width: 65%;
  height: 420px;
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
}

@media only screen and (max-width: 800px) {
  .successContainer {
    width: 100%;
    height: 100%; 
  }
}