/* moved stuff */

.form_row {
  display: flex;
  justify-content: space-between;
}

.form_column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 65%;
  align-self: center;
}

.form_column_mobile {
  width: 100%;
}

.form_row:first-child > .input_div,
.form_row:first-child > .date_picker {
  width: calc(33% - 8px);
}

.form_row:nth-child(2) > .input_div {
  width: calc(50% - 8px);
}

.container {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
}
.container_inner {
  padding: 20px 24px;
  display: flex;
  flex-flow: column;
  align-self: center;
  width: 100%;
  max-width: 648px;
  margin: 0 auto;
  box-sizing: border-box;
}

.arrow_icon {
  color: #595959;
}

.number_title {
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.page {
  background: #ffffff;
  display: flex;
  flex-flow: column;
  height: 80%;
  width: 100%;
}
.inner,
.inner_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 798px;
  height: 100%;
  padding: 12px;
  padding-top: 25px;
  margin: 0 auto;
}
.inner_mobile {
  padding-top: 25px;
}
.menu {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}
.title,
.mobile_title {
  margin-top: 32px;
  font-size: 24px;
  color: #4a4a4a;
  text-align: center;
  font-weight: bold;
}
.title h1,
.mobile_title h1 {
  font-size: 100%;
}

.subtitle,
.mobile_subtitle {
  font-size: 20px;
  color: #4a4a4a;
  text-align: center;
}
.mobile_subtitle {
  font-size: 16px;
  margin-bottom: -10px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.mobile_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}
.menu li {
  margin-bottom: 48px;
}
.page {
  color: #4a4a4a;
}
.active {
  color: #4a4a4a;
  font-weight: bold;
}
.footer {
  border-top: 1.5px solid #efefef;
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer_mobile {
  composes: footer;
  border: none;
  margin-top: 35px;
}

.footer_mobile button {
  margin-bottom: 50px;
}

.footer_mobile .policy_terms_statement {
  padding-bottom: 15px;
}

.assessment_footer {
  composes: footer;
  border: none;
}
.assessment_footer_mobile {
  composes: footer;
  height: 80px;
  padding: 0;
  border: none;
}

.footer_button_container {
  width: 85%;
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
}

.footer_button_container_mobile {
  composes: footer_button_container;
  display: block;
}

.action,
.mobile_action,
.second_assess_action {
  border-radius: 30px !important;
  text-transform: none !important;
  font-weight: normal !important;
  font-size: 18px !important;
  width: 170px !important;
  height: 55px !important;
}

.mobile_action,
.second_assess_action {
  height: 50px !important;
  width: 100% !important;
}

.second_assess_action {
  width: 50% !important;
}

.back {
  font-size: 18px;
  color: #4a4a4a !important;
  cursor: pointer;
  font-weight: bold;
}
.back:hover {
  text-decoration: underline;
}

.count_button,
.count_button_mobile {
  display: flex;
  flex-direction: row;
}
.count_button_mobile {
  justify-content: space-between;
  width: 60%;
}
.name {
  font-weight: 600;
}
.successlinks {
  display: flex;
  flex-flow: column;
}
.successlinks a {
  margin: 8px 0;
}
.linkContainer {
  cursor: pointer;
  padding: 16px 0;
  text-decoration: underline;
}
.successCheckContainer {
  height: 60px;
}
.successCheck {
  width: 100%;
  height: 100%;
}
.successtext {
  margin: 16px 0;
  line-height: 1.25;
}
/* original stuff */
.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.mobile_container,
.basics_mobile_container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  align-self: center;
  justify-content: flex-start;
  width: 75%;
  padding: 20px 0;
}

.mobile_container,
.basics_mobile_container {
  width: calc(100% - 48px);
  min-height: 100vh;
}
/* .basics_mobile_container {
  min-height: 90vh
} */
.title {
  align-self: center;
}
.error,
.connection_error,
.error_margin_bottom,
.error_link {
  color: #e02031;
  font-size: 14px;
  margin-top: 15px !important;
}
.error_link {
  text-decoration: underline
}
.error_margin_bottom {
  margin-top: 0 !important;
  margin-bottom: 20px;
}
.connection_error {
  margin-top: 20px;
  align-self: flex-start;
  font-size: 16px;
}
.intro_text {
  margin-top: 20px;
  line-height: 20px;
}
.number_background {
  background-color: #2c54e7;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.submitting_spinner {
  position: absolute;
  width: 100%;
  height: 85% !important; /* hack to prevent a scroll bar from showing up */
  background: rgba(255, 255, 255, 0.4);
}

/* basics */

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.clinician_button,
.clinician_button_placeholder {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: flex-start !important;
  text-transform: none !important;
  background-color: white !important;
  width: 100% !important;
  height: 56px !important;
  font-weight: normal !important;
  font-size: 16px !important;
  box-shadow: none !important;
  padding: 10.5px 14px !important;
  margin-bottom: 8px !important;
  margin-top: 30px !important;
  border: 1px solid #d1d1d1 !important;
  color: black !important;
}
.clinician_button:hover,
.clinician_button_placeholder:hover {
  border: 1px solid black !important;
}
.clinician_button_placeholder {
  color: #757575 !important;
}
.name_arrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.clinician_menu {
  width: 33.5% !important;
}

.input_div,
.phoneinput_div,
.date_picker {
  margin: 8px 0 !important;
}
.input {
  width: 100%;
}

.phoneinput_div {
  position: relative;
}
.phoneinput div:nth-child(1),
.phoneinput_mobile {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}
.phoneinput input {
  padding: 10px 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  border: 0;
  width: 260px;
  height: 34px;
  border-radius: 4px;
}
.hiddenPhoneLabel {
  display: none;
}

.phoneLabel,
.errorPhoneLabel {
  position: absolute;
  background-color: white;
  margin-left: 10px;
  padding: 0 5px;
  margin-bottom: 5px;
  font-size: 0.75rem;
  line-height: 0.2em;
  color: #2c55e8
}
.errorPhoneLabel {
  color: #f44336
}

.phoneinput > div:nth-child(2) {
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;
  margin: 8px 14px 0;
  color: #f44336;
}

.phoneinput_mobile > div {
  width: 100%;
}

.phoneinput_mobile > div > input {
  padding: 15px 14px;
  font-family: 'Roboto';
  font-size: 16px;
  border: 0;
  width: 300px;
  height: 18px;
  border-radius: 4px;
}

.phoneinput > div:nth-child(1):hover {
  border: 1px solid #212121;
}

.phoneinput > div:nth-child(1):focus-within {
  border: 2px solid #2c55e8
}

.phoneinput > div > div:first-child,
.phoneinput_mobile > div > div:first-child {
  display: none !important;
}

.header {
  margin-bottom: 10px;
  text-align: center;
}
.basics_text {
  margin-bottom: 16px;
  line-height: 1.25;
  font-size: 14px;
  color: #757575;
}
.phone_email_suggest_text {
  display: flex;
  width: 250px;
  line-height: 1.25;
  font-size: 11.5px;
  color: #757575;
  margin: 0 10px 0 auto;
}
.the_basics {
  font-weight: bold;
  font-size: 16px;
}
.successCheckContainer_basics {
  margin: 30px 0 20px 0;
  align-self: center;
  height: 60px;
  width: 60px;
}

.policy_terms_statement {
  font-size: 12px;
  line-height: 1.25;
  margin: 10px;
}

.policy_terms {
  color: #4a4a4a !important;
  font-size: 12px;
}

/* assessment */

.header {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0 20px;
  text-align: center;
  margin-top: -60px;
}
.section {
  line-height: 1.25;
  height: 105%;
  margin-bottom: 50px;
}
.assessment_number_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
  align-self: flex-start;
}
.assessment {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 30px;
  min-height: 500px;
}
.section_header {
  margin-bottom: 20px;
  line-height: 20px;
  color: #757575;
}
.questions {
  box-sizing: border-box;
  height: 100%;
}
.question {
  display: flex;
  flex-flow: column;
  margin: 15px 0;
  box-sizing: border-box;
}
.question_title {
  font-weight: 600;
  margin-bottom: 8px;
}
.question .answers {
  display: flex;
  flex-flow: column;
}
.question label {
  margin-bottom: 12px;
}
.button {
  margin: 0 auto;
}
.assessment_name {
  color: #80868b;
  margin-bottom: 15px;
}
.submit_button,
.mobile_submit_button {
  text-transform: none !important;
  font-weight: normal !important;
  width: 50% !important;
  align-self: center;
  margin-top: -15px !important;
}
.mobile_submit_button {
  width: 70% !important;
}
.bold {
  font-weight: bold;
}
.notRightNow {
  color: #88929b;
}
.notifyClinician {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: -12px;
  font-size: 14px;
  color: #757575;
}

@media only screen and (max-width: 425px) {
  .footer_button_container {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .footer {
    border: none;
  }
  .phone_email_suggest_text {
    display: flex;
    width: 100%;
    line-height: 1.25;
    font-size: 11.5px;
    color: #969b9f;
    margin: 0;
  }
  .action {
    border-radius: 30px !important;
    text-transform: none !important;
    font-weight: normal !important;
    font-size: 18px !important;
    width: 100% !important;
    height: 55px !important;
  }
  .input_div {
    margin: 8px 0 !important;
    width: 100%;
  }
  .form_column {
    width: 100%;
  }
  .clinician_menu {
    min-width: 90% !important;
  }
}

.consentError {
  color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

@media only screen and (min-width: 414px) {
  .basics_text {
    margin-bottom: 32px;
  }
  .number_title {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 800px) {
  .form_row:first-child > .input_div,
  .form_row:first-child > .date_picker {
    width: 100%;
  }

  .form_row:nth-child(2) > .input_div {
    width: 100%;
  }
  .form_row {
    flex-flow: column;
  }
}
