.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  max-width: 672px;
  margin: 0 auto;
  padding-top: 40px;
  padding-left: 16px;
  padding-right: 16px;
}

.title {
  font-size: 24px;
  line-height: 32.74px;
  font-weight: bold;
}

.main {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 21.82px;
}

.accept {
  border-radius: 30px !important;
  text-transform: none !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 48px;
}

.signout {
  font-size: 16px !important;
  line-height: 48px;
  padding-top: 10px;
  text-decoration: underline;
  color: #2d54e8;
  cursor: pointer;
}

.error {
  color: red;
  padding-bottom: 16px;
}