.final_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  margin: 0;
}
.bold {
  font-weight: bold;
}
.final_container p {
  margin-bottom: 20px;
  line-height: 1.25;
}
.successlinks {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: space-between;
}
.successdownloadbtn {
  display: flex;
  align-items: center;
}
.successdownloadbtn p {
  margin-bottom: 0;
}
.successdownloadbtn svg {
  margin-right: 10px;
}
.branch_link_button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  border-radius: 30px !important;
  text-transform: none !important;
  font-weight: normal !important;
  font-size: 16px !important;
  width: 100% !important;
  height: 55px !important;
  margin-bottom: 16px !important;
}
.enroll_another {
  color: #2c55e8;
  cursor: pointer;
  text-decoration: underline;
}
.successCheckContainer {
  height: 60px;
  width: 60px;
}
.successCheck {
  width: 100%;
  height: 100%;
}
.successtitle {
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.successtext {
  width: 80%;
}
.successContainer {
  width: 60%;
  height: 420px;
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
}

.error {
  color: #e02031;
  font-size: 14px;
}

@media only screen and (max-width: 800px) {
  .successContainer {
    width: 100%;
    height: 100%; 
  }
}